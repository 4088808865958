/**
 * Animate
 * ======================================
 * - add class to element in viewport
 */

const RATIO = "0.75";

export class Animate {
  constructor() {
    this.sections = document.querySelectorAll(".animate");

    window.addEventListener("scroll", this.scrollHandler, false);

    this.scrollHandler();
  }

  scrollHandler = () => {
    if (!document.querySelectorAll(".animate:not(.animate--visible)")) return;

    for (const section of this.sections) {
      if (
        section.getBoundingClientRect().top <= window.innerHeight * RATIO &&
        section.getBoundingClientRect().top > 0
      ) {
        setTimeout(() => {
          section.classList.add("animate--visible");
        }, 100);
      }
    }
  };
}

new Animate();
